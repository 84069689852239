<mat-toolbar class="header-navbar">

    <!-- desktop navigation -->
    <div class="d-none d-md-flex">
        <div class="d-flex gap-1">

            <!-- patients menu -->
            <button data-ef-button="nav-patients" mat-button [matMenuTriggerFor]="menuPatients" role="button">
                {{'NAVIGATION.PATIENTS'|translate}}</button>

            <mat-menu #menuPatients="matMenu">
                <ng-template #currentPatient matMenuContent>
                    <a data-ef-link="nav-patient-demographics" mat-menu-item [href]="patient.demographicsLink|link"
                        *ngIf="patient">
                        {{ 'NAVIGATION.RETURN_TO' | translate : { fullname: patient.fullName } }}</a>
                </ng-template>
            </mat-menu>

        </div>
    </div>

    <!-- mobile navigation -->
    <div class="d-flex d-md-none flex-fill">
        <button data-ef-button="nav-mobile-menu" mat-icon-button [matMenuTriggerFor]="menuMobile" role="button">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menuMobile="matMenu" class="mobile-nav-menu">
            <mat-accordion>

                <!-- patients -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header (click)="$event.stopPropagation()">
                        <mat-panel-title>{{'NAVIGATION.PATIENTS'|translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container [ngTemplateOutlet]="currentPatient"></ng-container>
                </mat-expansion-panel>

            </mat-accordion>
        </mat-menu>
    </div>

    <!-- quick list -->
    <div class="d-flex flex-fill justify-content-end">
        <button data-ef-button="nav-quick-list" mat-button [matMenuTriggerFor]="menuQuickList" role="button">
            {{'NAVIGATION.QUICK_LIST'|translate}}</button>
        <mat-menu #menuQuickList="matMenu" class="no-max-width" xPosition="before">
            <ng-template matMenuContent>
                <pm-quick-list></pm-quick-list>
            </ng-template>
        </mat-menu>
    </div>

</mat-toolbar>