import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISecurity } from '../interfaces/security';
import { IWindowIdleTimeoutPlus } from '../interfaces/window-idle-timeout-plus';

/** Used to logout the user. */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const window: IWindowIdleTimeoutPlus;

/**
 * Security service.
 */
@Injectable({
  providedIn: 'root'
})
export class SecurityService implements ISecurity {
  /** API controller. */
  controller = 'Home';
  /** Has dashboard role. */
  hasDashboardRole = new BehaviorSubject<boolean>(false);
  /** Session checker interval timer */
  private sessionCheckerInterval: undefined | number;
  /** Session call pending */
  isSessionCallPending = false;
  /** Last session check timestamp */
  lastSessionCheck = 0;
  /** Broadcast channel for session invalidation */
  sessionChannel = new BroadcastChannel('session-channel');

  /**
   * Initializes instance of the SecurityService class.
   * @param http Http client.
   */
  constructor(private http: HttpClient) {
    this.setDashboardRole();
    this.setSessionInterval();
    this.sessionMessageHandler();
  }

  /** Does the user have access to the Logi dashboard? */
  get hasDashboardRole$(): Observable<boolean> {
    return this.hasDashboardRole.asObservable();
  }

  /**
   * Function to stop the session checker interval
   */
  stopSessionChecker(): void {
    if (this.sessionCheckerInterval !== undefined) {
      clearInterval(this.sessionCheckerInterval);
    }
  }

  /**
   * Logout.
   */
  logout(): void {
    this.stopSessionChecker();
    window.onbeforeunload = null;
    sessionStorage.setItem('Account', '{}');
    this.idleTimeoutPlusLogout();
  }

  /**
   * idleTimeoutPlus Logout.
   */
  idleTimeoutPlusLogout(): void {
    window.IdleTimeoutPlus.logout();
  }

  /**
   * Determine if the user has access to the Logi dashboard.
   */
  setDashboardRole(): void {
    const url = `${this.controller}/GetUserRoles`;
    this.http.get<boolean>(url).subscribe({
      next: value => this.hasDashboardRole.next(value)
    });
  }

  /**
   * Check if session is valid.
   */
  getIdpSessionChecker(): void {
    const now = Date.now();
    if (this.isSessionCallPending || now - this.lastSessionCheck < 20000) {
      return;
    }

    this.isSessionCallPending = true;
    this.lastSessionCheck = now;
    const url = `${this.controller}/IsSessionValid`;
    this.http.get<boolean>(url).subscribe({
      next: isValid => {
        this.isSessionCallPending = false;
        if (!isValid) {
          this.sessionChannel.postMessage('invalidate-session');
        }
      },
      error: () => {
        this.isSessionCallPending = false;
        this.sessionChannel.postMessage('invalidate-session');
      }
    });
  }

  /**
   * Handle session message.
   * @param event The message event.
   */
  handleSessionMessage(event: MessageEvent): void {
    if (event.data === 'invalidate-session') {
      sessionStorage.setItem('isTimedOut', '1');
      this.logout();
    }
  }

  /**
   * Session message handler.
   */
  private sessionMessageHandler(): void {
    this.sessionChannel.onmessage = (event): void => this.handleSessionMessage(event);
  }

  /**
   * Set session interval.
   */
  private setSessionInterval(): void {
    this.sessionCheckerInterval = window.setInterval(() => this.getIdpSessionChecker(), 20000);
  }
}
