import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { LinkPipe } from '@pm/core';
import { PatientDetail, PatientsService } from '@pm/core/patients';
import { QuickListComponent } from '../quick-list/quick-list.component';

/**
 * Component for the navigation section of the header (orders).
 */
@Component({
  selector: 'pm-navigation-orders',
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    QuickListComponent,
    TranslateModule
  ],
  templateUrl: './navigation-orders.component.html',
  styleUrls: ['./navigation-orders.component.scss']
})
export class NavigationOrdersComponent {
  /** Patient detail. */
  public patient?: PatientDetail;

  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /** Patient internal id. */
  @Input() set patientInternalId(value: number | undefined) {
    this.setPatient(value);
  }

  /**
   * Initializes an instance of the NavigationOrdersComponent class.
   * @param config Application configuration service
   * @param patients Patient demographics service
   */
  constructor(private patients: PatientsService) { }

  /**
   * Set current patient.
   * @param patientInternalId Patient internal id
   */
  async setPatient(patientInternalId?: number): Promise<void> {
    this.patient = patientInternalId ? await this.patients.getPatient(patientInternalId).catch(() => undefined) : undefined;
  }
}
