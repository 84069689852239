import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { AlertComponent, IAlert } from '@pm/core/alerts';
import { AppFeatureService } from '@pm/core/configuration';
import { PatientsService } from '@pm/core/patients';
import { DialogHelper } from '@pm/layout';
import { ReviewPatientsDialog } from './dialogs/review-patients/review-patients.dialog';

/**
 * Component for review patients alert message.
 */
@Component({
  selector: 'pm-review-patients-alert',
  templateUrl: './review-patients-alert.component.html',
  styleUrls: ['./review-patients-alert.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    CommonModule,
    MatDialogModule,
    TranslateModule
  ]
})
export class ReviewPatientsAlertComponent implements OnDestroy, OnInit {
  /** Review patients alert with default values. */
  public alert: IAlert = { type: 'Warning' };
  /** Review patients alert message. */
  public message = '';
  /** Number of patients added from the online scheduler that need review. */
  public patientCount = 0;

  /** Helper class for dialogs. */
  private dialogHelper = new DialogHelper();
  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /** Alert options. */
  @Input() set options(alert: IAlert) {
    Object.assign(this.alert, alert);
  }

  /** Template used to render content outside of selector. */
  @ViewChild('template') template!: TemplateRef<never>;

  /**
     * Initializes instance of the ReviewPatientsAlertComponent class.
     * @param changeDetectorRef Change detector
     * @param dialog Material dialog service.
     * @param feature Application feature service
     * @param patients Patients service
     */
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private feature: AppFeatureService,
    private patients: PatientsService
  ) { }

  /**
  * OnInit lifecycle hook.
  * - Get patient review count.
  */
  async ngOnInit(): Promise<void> {
    this.getPatientReviewCount();
  }

  /**
   * OnDestroy lifecycle hook.
   * - Unsubscribe from observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Show patient review dialog. TODO - replace with material dialog.
   * @param event DOM event
   */
  review(event: Event): void {
    event.preventDefault();

    this.dialog.open(ReviewPatientsDialog, this.dialogHelper.large());
  }

  /**
   * Set the number of patients added from the online scheduler that need review.  Also determines
   * the message to display.
   */
  async getPatientReviewCount(): Promise<void> {
    if (await this.feature.isReviewPatientsCreatedOnlineEnabled()) {
      this.patientCount = await this.patients.getPatientReviewCount();
      this.setMessage();
    } else {
      this.patientCount = 0;

    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Set warning message.
   */
  private setMessage(): void {
    this.message = `ALERTS.PATIENTS_REVIEW.${this.patientCount === 1 ? 'SINGULAR' : 'PLURAL'}`
  }
}
