import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivationStart, Data, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LinkPipe } from '@pm/core';
import { AppConfigurationService } from '@pm/core/configuration';
import { NavLinkDirective } from '@pm/layout';
import { LogoComponent, PremierLogoComponent } from '@pm/layout/logo';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NavigationClaimsComponent } from './components/navigation-claims/navigation-claims.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { NavigationOrdersComponent } from './components/navigation-orders/navigation-orders.component';

/**
 * Header component.
 */
@Component({
  selector: 'pm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LinkPipe,
    NavigationClaimsComponent,
    NavigationOrdersComponent,
    NavigationComponent,
    NavLinkDirective,
    ToolbarComponent,
    LogoComponent,
    PremierLogoComponent
  ]
})
export class HeaderComponent implements OnDestroy, OnInit {
  /** Is browser in fullscreen mode. */
  public isFullscreen = !!document.fullscreenElement;
  /** Is navigation enabled? */
  public isNavigationEnabled = this.config.isNavigationEnabled;
  /** Is VSP Premier Edge? */
  public isPremier = this.config.isPremier;
  /** Patient internal id. */
  public patientInternalId?: number;

  /** Route data. */
  private routeData: Data = {};
  /** Array of subscriptions to observables. */
  private subscriptions: Subscription[] = [];

  /** Header mode. (calendar or standard) */
  @Input() mode: 'calendar' | 'standard' = 'standard';
  /** Navigation mode. */
  @Input() navigation: Navigation = null;

  /** Detect fullscreen browser mode. */
  @HostListener('window:resize', ['$event.target'])
  onResize(): void {
    this.isFullscreen = !!document.fullscreenElement;
    this.isFullscreen ? document.body.classList.add('fullscreen') : document.body.classList.remove('fullscreen');
  }

  /**
   * Initializes instance of the HeaderComponent class.
   * @param config Application configuration service.
   */
  constructor(
    private config: AppConfigurationService,
    private router: Router
  ) { }

  /**
   * OnInit lifecycle hook.
   * - Set navigation mode
   */
  ngOnInit(): void {
    if (this.config.isRoutingAvailable) {
      this.subscriptions.push(this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.patientInternalId = undefined;
        } else if (event instanceof ActivationStart) {
          this.routeData = event.snapshot.data ?? this.routeData;
          this.patientInternalId = event.snapshot.params['patientInternalId'] ?? this.patientInternalId;
        } else if (event instanceof NavigationEnd) {
          this.navigation = this.routeData['navigation'] ?? 'default';
        }
      }));
    } else {
      this.navigation = this.navigation ?? 'default';
      this.setPatientId();
    }
  }

  /**
   * OnDestroy lifecycle hook.
   * - Unsubscribe from observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Set patient id on legacy pages.
   */
  private setPatientId(): void {
    const pathname = document.location.pathname.toLowerCase();
    if (pathname.includes('/patient/materialorders')) {
      const match = location.search.match(/[?&]id=(\d+)/i);
      match && (this.patientInternalId = Number(match[1]));
    }
  }
}

/** Type for navigation modes. */
type Navigation = 'default' | 'claims' | 'orders' | null;