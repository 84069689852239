import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { catchError, from, Observable, throwError } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { AppConfigurationService, IAppWindow } from '@pm/core/configuration';
import { SystemFailureAlertComponent } from '@pm/core/alerts';
import { TemporaryOverrideDialog } from '@pm/core/security';

/** Extened window DOM document. */
declare const window: IAppWindow;

/**
 * HTTP interceptor for EPM API calls.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  /**
   * Initializes instance of the ApiInterceptor class.
   * @param config Application configuration service
   * @param snackBar Material snackbar
   * @param translate Translation service
   */
  constructor(
    private config: AppConfigurationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  /**
   * Itercept HTTP request.
   * @param request HTTP request
   * @param next HTTP handler
   * @returns {Observable}
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.handle(request, next);
  }

  /**
   * Add common headers to request.
   * @param request HTTP request
   * @param next HTTP handler
   * @returns Observable
   */
  handle(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.indexOf('assets') < 0) {
      // get anti forgery token
      const antiForgeryToken = this.config.antiForgeryToken;

      // if token exists, update request, otherwise throw error
      if (antiForgeryToken) {
        const useOffice = (request.url.match(/Login|AdditionalIntegrations|Home|CompanyInformation/gi)?.length || 0) === 0;
        const useExternal = (request.url.match('https:')?.length ?? 0) > 0;

        // If it's an external endpoint, don't prepend the base URL
        const url = useExternal ? request.url : `${this.getApiBaseUrl(useOffice)}/${request.url}`;

        Object.assign(
          request, {
          url,
          headers: new HttpHeaders({
            'RequestVerificationToken': antiForgeryToken,
            'content-type': 'application/json; charset=utf-8'
          }),
          responseType: 'json'
        });
      } else {
        throw new Error(this.translate.instant('TOKEN_NOT_FOUND') as string);
      }

      // return updated request
      request = request.clone();
    }

    return next.handle(request)
      .pipe(catchError((error: HttpErrorResponse) => {
        let isTimeout = false;

        if (error.status === 419) {
          isTimeout = true;
          const url = window.parent.location.href;
          if (url.indexOf('Login') < 0) {
            window.location.href = `${this.config.getLinkPath('Login')}?ReturnUrl=${encodeURIComponent(url)}`;
          } else {
            location.reload();
          }
        }

        // handle permission override
        if (error.status === 497) {
          const value = new Promise<HttpResponse<unknown>>(resolve => {
            const dialogRef = this.dialog.open(TemporaryOverrideDialog, { panelClass: 'pm-dialog-md' });
            dialogRef.afterClosed().subscribe(result => resolve(new HttpResponse({ status: 200, body: result === 'override' })));
          });
          return from(value);
        }

        if (!request.url.match(/IsSessionValid|i18n|SuppressAlerts=1/gi)) {
          this.snackBar.openFromComponent(SystemFailureAlertComponent, {
            duration: 6000,
            panelClass: isTimeout ? 'timeout' : 'system-failure',
            verticalPosition: 'top',
            data: { error, request }
          });
        }
        return throwError(() => new Error(error.message));
      }))
  }

  /**
   * Get API base url. 
   * @param useOffice Use office? 
   * @returns {string}
   */
  private getApiBaseUrl(useOffice: boolean): string {
    const url = this.config.baseUrl;
    const officeExternalId = this.config.officeExternalId;

    if (useOffice && officeExternalId) {
      return `${url.endsWith('/') ? url.substring(0, url.length - 1) : url}/api/office/${officeExternalId}`;
    } else {
      return `${url.endsWith('/') ? url.substring(0, url.length - 1) : url}/api`;
    }
  }
}
