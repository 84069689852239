/* eslint-disable quotes */
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest, filter, map, take } from 'rxjs';
import { DatePipe } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AppConfigurationService } from '@pm/core/configuration';

import { IEnvironmentConfig } from '@pm/core';
import { AlertType } from '@pm/core/alerts';
import { StoreOperationsWarningDialog } from '../dialogs/store-operations-warning/store-operations-warning.dialog';
import { IAdminFeature } from '../interfaces/admin-feature';
import { IPreviousUnclosedBusinessDaysInfo } from '../interfaces/previous-unclosed-business-days-info';
import { IStoreOperationsAlert } from '../interfaces/store-operations-alert';

/**
 * Service for store operations.
 */
@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  /** Are patient nav order links disable? */
  private isPatientNavOrderLinksDisabled = new BehaviorSubject<boolean>(false);
  /** Is admin dashboard loading? */
  private isAdminLoading = new BehaviorSubject<boolean>(false);
  /** Is unclosed business days info loaded? */
  private isUnclosedBusinessDaysInfoLoaded = new BehaviorSubject<boolean>(false);
  /** Store operations alert.  */
  private storeOperationsAlert = new BehaviorSubject<IStoreOperationsAlert>({});

  /**
   * Initializes instance of the PatientsService class.
   * @param config Application configuration service
   * @param datePipe Date pipe
   * @param dialog Material dialog service
   * @param http HTTP client
   * @param translate Translation service
   * @param environment Environment config
   * - Get previous unclosed business days info.
   */
  constructor(
    private config: AppConfigurationService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private http: HttpClient,
    private translate: TranslateService,
    @Inject('environment') private environment: IEnvironmentConfig
  ) {
    this.loadPreviousUnclosedBusinessDaysInfo();
  }

  /**
   * Is admin link disabled?
   */
  get isAdminLinkDisabled$(): Observable<boolean> {
    return combineLatest([this.isUnclosedBusinessDaysInfoLoaded, this.isAdminLoading])
      .pipe(map(([isUnclosedBusinessDaysInfoLoaded, isAdminLoading]) => (this.isStoreOperations && !isUnclosedBusinessDaysInfoLoaded) || isAdminLoading));
  }

  /**
   * Are patient nav order links disable? (observable for other components to subscribe)
   */
  get isPatientNavOrderLinksDisabled$(): Observable<boolean> {
    return this.isPatientNavOrderLinksDisabled.asObservable();
  }

  /** Is the current page store operations? */
  get isStoreOperations(): boolean {
    const links = this.environment.links;
    const pathname = document.location.pathname.toLowerCase();
    return pathname.indexOf(links.dailyClosing.toLowerCase()) >= 0
      || pathname.indexOf(links.dailyOpening.toLowerCase()) >= 0
      || pathname.indexOf(links.tillDailyClosing.toLowerCase()) >= 0;
  }

  /** Is the current page dailiy close? */
  get isDailyClose(): boolean {
    const links = this.environment.links;
    const pathname = document.location.pathname.toLowerCase();
    return pathname.indexOf(links.dailyClosing.toLowerCase()) >= 0
      || pathname.indexOf(links.tillDailyClosing.toLowerCase()) >= 0;
  }

  /** Is the current page daily open? */
  get isDailyOpen(): boolean {
    const links = this.environment.links;
    const pathname = document.location.pathname.toLowerCase();
    return pathname.indexOf(links.dailyOpening.toLowerCase()) >= 0;
  }

  /**
   * Store operations alert. (observable for other components to subscribe)
   */
  get storeOperationsAlert$(): Observable<IStoreOperationsAlert> {
    return this.storeOperationsAlert.asObservable();
  }

  /**
   * Goto administration.
   */
  goToAdmin(): void {
    this.isAdminLoading.next(true);
    if (this.isStoreOperations) {
      this.isUnclosedBusinessDaysInfoLoaded.pipe(filter(x => x), take(1)).subscribe(() => {
        if (this.storeOperationsAlert.value.message) {
          const dialog = this.dialog.open(StoreOperationsWarningDialog, { panelClass: 'pm-dialog-md' });
          dialog.afterClosed().subscribe(x => x === 'skip' ? this.navigateToAdmin() : this.isAdminLoading.next(false));
        } else {
          this.navigateToAdmin();
        }
      });
    } else {
      this.navigateToAdmin();
    }
  }

  /**
   * Load previous unclosed business days info.
   */
  async loadPreviousUnclosedBusinessDaysInfo(): Promise<IStoreOperationsAlert> {
    return new Promise(resolve=> {
    const officeExternalId = this.config.officeExternalId;
    if (officeExternalId) {
      const url = `DailyClosing/GetPreviousUnclosedBusinessDaysInfo?officeNumber=${encodeURIComponent(officeExternalId)}`;
      this.http.get<IPreviousUnclosedBusinessDaysInfo>(url).subscribe({
        next: data => resolve(this.setStoreOperationsAlert(data)),
        error: () => {
          this.storeOperationsAlert.next({})
          resolve({});
        }
      })
    } else {
      resolve({});
    }
  });
  }

  /**
   * Navigate to the appropriate admin dashboard based on the feature flag.
   */
  private async navigateToAdmin(): Promise<void> {
    const url = 'Home/GetModernAdminFeature';
    const feature = await new Promise<IAdminFeature | null>(resolve => this.http.get<IAdminFeature>(url).subscribe({
      next: feature => resolve(feature),
      error: () => resolve(null)
    }));

    sessionStorage.removeItem('adminOfficeContext');
    window.location.href = feature?.IsModernAdminEnabled ? feature.EncompassAdminUrl : this.config.getLinkPath('Admin');
    this.isAdminLoading.next(false);
  }

  /**
   * Set store operations alert.
   * @param data Previous unclosed business days info
   */
  private setStoreOperationsAlert(data: IPreviousUnclosedBusinessDaysInfo): IStoreOperationsAlert {
    let postingDate = data.UnclosedDaysList?.length ? this.datePipe.transform(data.UnclosedDaysList[0], 'M/d/yyyy') : null;
    const unclosedDaysCount = data.UnclosedDaysCount;
    const tillEnabled = data.IsTillFeatureEnabled;
    const title = this.translate.instant('ALERTS.STORE_OPERATIONS.TITLE');
    let link = this.environment.links.dailyClosing;
    let message: string | undefined;
    let operationType: 'closing' | 'opening' = 'closing';
    let alertType: AlertType = 'Error';

    // set message
    if (tillEnabled) {
      if (!data.IsDayOpen) {
        message = this.translate.instant('ALERTS.STORE_OPERATIONS.NOT_OPENED');
        link = this.environment.links.dailyOpening;
        operationType = 'opening';
      }
      if (data.IsTodayClosed) {
        message = this.translate.instant('ALERTS.STORE_OPERATIONS.TODAY_CLOSED_TILL');
        link = this.environment.links.tillDailyClosing;
        operationType = 'closing';
      }
      if (data.IsTodayClosingInProcess) {
        message = this.translate.instant('ALERTS.STORE_OPERATIONS.TODAY_CLOSING_IN_PROCESS');
        link = this.environment.links.tillDailyClosing;
        operationType = 'closing';
      }
      if (unclosedDaysCount > 0) {
        message = this.translate.instant('ALERTS.STORE_OPERATIONS.UNCLOSED_DAYS', { postingDate });
        link = this.environment.links.tillDailyClosing;
        operationType = 'closing';
      }
      if (data.UnopenedDaysCount > 0 && data.UnopenedDaysList?.length) {
        let dayStatus = 'previous';
        if (data.UnopenedDaysList?.length && (new Date(data.UnopenedDaysList[0])).getTime() === (new Date(data.ServerDateToday)).getTime()) {
          dayStatus = 'current';
        }
        postingDate = this.datePipe.transform(data.UnopenedDaysList[0], 'M/d/yyyy');
        message = this.translate.instant('ALERTS.STORE_OPERATIONS.UNOPENED_DAYS', { dayStatus, postingDate });
        link = this.environment.links.dailyOpening;
        operationType = 'opening';
      }

      // update patient nav order links disabled flag
      this.isPatientNavOrderLinksDisabled.next(Boolean(message) || data.IsReopenEnabled);
    } else {
      let disableNavLinks = true;
      if (unclosedDaysCount === 0) {
        alertType = 'Info';
        if (data.IsTodayClosed) {
          message = this.translate.instant('ALERTS.STORE_OPERATIONS.TODAY_CLOSED');
        } else {
          disableNavLinks = false;
        }
      } else {
        if (!data.IsSmbDailyClosingEnabled) {
          message = this.translate.instant('ALERTS.STORE_OPERATIONS.SMB_DAILY_CLOSING_DISABLED');
          if (!this.config.dailyRoles.hasDayCloseRole) {
            message += `<br />${this.translate.instant('ALERTS.STORE_OPERATIONS.NO_ROLE')}`;
          }
        } else if (unclosedDaysCount === 1) {

          if (data.IsDailyClosingRequired) {
            message = this.translate.instant('ALERTS.STORE_OPERATIONS.DAILY_CLOSING_REQUIRED', { postingDate });
          } else {
            message = this.translate.instant('ALERTS.STORE_OPERATIONS.DAILY_CLOSING_NOT_REQUIRED', { postingDate });
            disableNavLinks = false;
          }
        } else {
          if (this.config.userId !== 0 && this.config.dailyRoles.hasDayCloseRole) {
            message = this.translate.instant('ALERTS.STORE_OPERATIONS.MULTIPLE_UNCLOSED_DAYS', { postingDate });
          } else {
            message = this.translate.instant('ALERTS.STORE_OPERATIONS.MULTIPLE_UNCLOSED_DAYS_NO_ROLE');
          }
        }
      }

      // update patient nav order links disabled flag
      this.isPatientNavOrderLinksDisabled.next((message?.length && disableNavLinks) || data.IsReopenEnabled);
    }

    // update store operations alert
    const storeOperationsAlert = { link, message, title, type: alertType, operationType };
    this.storeOperationsAlert.next(message ? storeOperationsAlert : {});
    this.isUnclosedBusinessDaysInfoLoaded.next(true);
    return storeOperationsAlert;
  }
}
