import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';

import { AppConfigurationService } from '@pm/core/configuration';
import { OverrideResult } from '../classes/override-result';
import { ILoginResponse } from '../interfaces/login-response';

@Injectable()
export class LoginService {
  /** API controller. */
  private controller = 'Login';
  /** Value used for encryption. */
  private key = CryptoJS.enc.Utf8.parse('5990678078928356');
  /** Value used for encryption. */
  private iv = CryptoJS.enc.Utf8.parse('5990678078928356');
  /** Number of login attempts. */
  private numberOfAttempts = 0;


  /**
   * Initializes instance of the LoginService class.
   * @param config Application configuration class
   * @param http HTTP client
   * @param translate Translate service
   */
  constructor(
    private config: AppConfigurationService,
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  /**
   * Process the permissions override
   * @param username Username
   * @param password Password
   * @returns Override result
   */
  processOverride(username: string, password: string): Promise<OverrideResult> {
    const url = `${this.controller}/ProcessOverride`;
    const body = {
      'PracticeLocationId': this.config.officeExternalId,
      'Action': 'Verify',
      'LoginName': this.encrypt(username),
      'Password': this.encrypt(password),
      'NumberOfAttempts': this.numberOfAttempts++,
      'NumberOfPasswordResetAttempts': this.numberOfAttempts++
    };
    return new Promise<OverrideResult>(resolve => {
      this.http.post<ILoginResponse>(url, body, { withCredentials: true }).subscribe({
        next: result => {
          switch (result.PasswordCheckResult) {
            case 'Verified':
            case 'ChangePassword':
            case 'MeetsPolicy':
              resolve(new OverrideResult(true, null, result.OverrideUserId));
              break;
            case 'Inactive':
            case 'NoPermission': {
              const message = this.translate.instant('MESSAGES.LOGIN_NO_PERMISSION');
              resolve(new OverrideResult(false, { message, type: 'Error' }));
              break;
            }
            case 'NoCookie': {
              const message = this.translate.instant('MESSAGES.LOGIN_NO_COOKIE');
              resolve(new OverrideResult(false, { message, type: 'Error' }));
              break;
            }
            case 'IsLockedOut': {
              const message = this.translate.instant('MESSAGES.LOGIN_LOCKED_OUT');
              resolve(new OverrideResult(false, { message, type: 'Error' }));
              break;
            }
            case 'NoMatch': {
              let message = this.translate.instant('MESSAGES.LOGIN_NO_MATCH');
              const title = this.translate.instant('MESSAGES.LOGIN_NO_MATCH_TITLE');

              if (result.NumberOfAttempts > 2) {
                const left = 5 - result.NumberOfAttempts;
                const resetLeft = 5 - result.NumberOfPasswordResetAttempts;
                if (left > 0) {
                  message += this.translate.instant('MESSAGES.LOGIN_FAILED_ATTEMPTS', { left });
                } else {
                  if (resetLeft > 0) {
                    message = this.translate.instant('MESSAGES.LOGIN_EXCEEDED_LOGIN');
                  } else {
                    message = this.translate.instant('MESSAGES.LOGIN_EXCEEDED_RESET');
                  }
                }
              }
              const overrideResult = new OverrideResult(false, { message, title, type: 'Error' });
              resolve(overrideResult);
              break;
            }
            default: {
              const message = this.translate.instant('MESSAGES.LOGIN_PASSWORD_CHECK');
              const title = this.translate.instant('MESSAGES.LOGIN_PASSWORD_CHECK_TITLE');
              resolve(new OverrideResult(false, { message, title, type: 'Error' }));
              break;
            }
          }
        },
        error: () => {
          const message = this.translate.instant('MESSAGES.LOGIN_ERROR');
          resolve(new OverrideResult(false, { message, type: 'Error' }));
        }
      });
    });
  }

  /**
  * Change the office location
  *
  * @param officeNum  The office number to change to
  * @returns  The login view model
  */
  async changeOffice(officeNum: string): Promise<ILoginResponse | null> {

    return new Promise<ILoginResponse | null>(resolve => {
      const url = `${this.controller}/ChangeOffice`;

      this.http.post<ILoginResponse | null>(url, JSON.stringify(officeNum)).subscribe({
        next: data => {
          this.oidcSetStorageVariables(data ?? null);
          resolve(data ?? null);
        },
        error: () => {
          resolve(null);
        }
      });
    });
  }

    /**
   * Set storage variables that come back as a part of logging in.
   * "oidc" means open Id connect and the name has been copied from practicemanagement.
   *  
   * @param userData  User data to set in local storage.
   */
    private oidcSetStorageVariables(userData : ILoginResponse | null) : void{
      if (userData != null && userData != undefined) {
          window.localStorage.setItem('IsEcrOnPrem', userData.IsOnPremEcrVault.toString());
          window.localStorage.setItem('IsChwEnabled', userData.IsChwEnabled.toString());
          window.localStorage.setItem('IsBtnClickRequiredForPatientSearch', userData.IsBtnClickRequiredForPatientSearch.toString());
          window.localStorage.setItem('IsVSPPremierDashboardEnabled', userData.IsVSPPremierDashboardEnabled.toString());
          window.localStorage.setItem('IsPaymentAuthApi', userData.IsPaymentAuthApi.toString());
          window.localStorage.setItem('IsHideBillingInfoInFrontOfficeReportingEnabled', userData.IsHideBillingInfoInFrontOfficeReportingEnabled.toString());
          window.localStorage.setItem('IsAnalyticsAndInsightsEnabled', userData.IsAnalyticsAndInsightsEnabled.toString());
          window.localStorage.setItem('IsScheduleApptforotherOfficesEnabled', (userData.IsScheduleApptforotherOfficesEnabled ?? false).toString());
          window.localStorage.setItem('IsPriceTransparencyEnabled', userData.IsPriceTransparencyEnabled.toString());
          window.localStorage.setItem('IsShowEPMFeatureConfigurationInALAdminEnabled', userData.IsShowEPMFeatureConfigurationInALAdminEnabled.toString());
          window.localStorage.setItem('IsNewPricingScreenEnabled', userData.IsNewPricingScreenEnabled.toString());
          window.localStorage.setItem('HideListPrice', userData.HideListPrice.toString());
          window.localStorage.setItem('RequireOCHeight', userData.RequireOCHeight.toString());
          window.localStorage.setItem('CombineBifocalSplitPD', userData.CombineBifocalSplitPD.toString());
          if (userData.IsTillEnabled) {
              window.localStorage.setItem('IsTillEnabled', userData.IsTillEnabled.toString());
          } else {
              window.localStorage.removeItem('IsTillEnabled');
          }
  
          window.sessionStorage.setItem('FrameDataMessage', (userData.FrameDataMessage ?? '').toString());
      }
    }

  /**
   * Encrypt string.
   * @param data Input string
   * @returns Encrypted string 
   */
  private encrypt(data: string): string {
    return CryptoJS.AES.encrypt(data, this.key, {
      keySize: 128 / 8,
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }
}
